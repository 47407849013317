
export default {
  namespaced: true,
  state: {
    pageTitle: 'home',
    timePickerSelectorLoader: false,
    timePickerSelectorDate: null,
  },
  getters: {
    getSelectedDate(state) {
      return state.timePickerSelectorDate
    }
  },
  mutations: {
    SET_PAGE_TITLE(state, title) {
      state.pageTitle = title
    },
    SET_TIME_PICKER_SELECTOR_LOADER(state, status) {
      state.timePickerSelectorLoader = status
    },
    SET_TIME_PICKER_SELECTOR_LOADER(state, status) {
      state.timePickerSelectorLoader = status
    },
    SET_TIME_PICKER_SELECTOR_DATE(state, date) {
      state.timePickerSelectorDate = date
    }
  },
  actions: {},
}
