import Vue from 'vue'
import BootstrapVue, { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueSimpleSVG from 'vue-simple-svg'

import { setFilterData, getFilterData, deleteKeyValue, getSearchData, setSortData, getSortData } from '@core/mixins/filterdata'

import { containsAny } from '@core/mixins/arraysOperations'
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

import VueMask from 'v-mask'
import App from './App.vue'
import store from './store'
import router from './router'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.use(VueSimpleSVG)

Vue.use(BootstrapVue, {
  breakpoints: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
})

Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = event => {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})

new Vue({
  router,
  store,
  mixins: [setFilterData, getFilterData, deleteKeyValue, containsAny, getSearchData, setSortData, getSortData],
  render: h => h(App),

}).$mount('#app')

Vue.prototype.$googleLoaded = false
Vue.prototype.$googleMap = null

// eslint-disable-next-line no-extend-native
Array.prototype.random = () => this[Math.floor((Math.random() * this.length))]

Vue.use(VueMask)
Vue.prototype.$eventHub = new Vue()
const axios = require('axios')

window.axiosAPI = axios
window.globalRouter = router
Vue.prototype.$inDevelopmentMode = process.env.NODE_ENV === 'development'


Vue.prototype.$getPostData = (postData, dates) => {
  const returnData = postData
  if (dates) {
    if (dates.start_date) {
      returnData.start_date = dates.start_date
    }
    if (dates.end_date) {
      returnData.end_date = dates.end_date
    }
  }
  return postData
}

Vue.prototype.$getMinAndMax = async (array, key = 1) => {
 var min = Number.POSITIVE_INFINITY;
 var max = Number.NEGATIVE_INFINITY;
 var tmp;
 for (var i=array.length-1; i>=0; i--) {
     tmp = array[i][key];
     if (tmp < min) min = tmp;
     if (tmp > max) max = tmp;
 }
 return {
  max,
  min
 }
}



