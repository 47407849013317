<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >

    <b-row v-if="isLoggedIn && showPageTimePicker" class="shared-page-title-and-date-range">
      <b-col class="page-title">
        {{ store.state.menuConfig.pageTitle }}
      </b-col>

      <b-col
      cols="12"
      sm="5"
        class="page-time-tools text-center"
      >
        <ul
          class="page-time-tools-items list-inline"
        >
        <li v-if="store.state.menuConfig.timePickerSelectorLoader">
            <b-spinner class="page-date-picker-spinner float-right" small  label="Loading..." />
        </li>

          <li v-for="(period,index ) in periodsOptions" 
            @click="changePeriod(index)"  
            :class="period.active ? 'active' : 'inactive'" 
            class="page-time-tools-item interval-btn">
            {{ period.text }}
          </li>
 
          <li class="page-time-tools-item interval-btn"  
          :class="hasDate ? 'has-date' : ''">
                <VueDatePicker
                    ref="pageDatePicker"
                    v-model="selectedDate"
                    :max-date="maxDate"
                    range
                    :fullscreen-mobile="false"
                    validate
                    :format="dataFormat"
                    placeholder="Choose date"
                    clearable
                    @onChange="dateChanged(selectedDate)"
                >
  
                <template #activator="{ date }">
                    <div
                      class="custom-time-range-button"
                      ref="activator"
                    >
                      <img
                        src="@/assets/images/icons/general-icons/calendar-icon.png"
                        width="16px"
                      >
                    </div>
                    <b-row class="date-section" v-if="date">
                      <b-col>
                        {{ date }}
                      </b-col>
                    </b-row>                  
                </template>
              </VueDatePicker>
          </li>
          <li class="page-time-tools-item" v-if="showIntervalPicker">
            <b-form-select
              v-model="selectedInterval"
              :options="intervalOptions"
              @change="changeInterval"
              class="page-interval-selector"
            />
          </li>



        </ul>
      </b-col>
    </b-row>
    <component :style="!showPageTimePicker ? 'padding-top:85px' : ''" :is="layout">
      <router-view />
    </component>

  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { isUserLoggedIn } from '@/auth/utils'

import { useWindowSize, useCssVar } from '@vueuse/core'
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';

import store from '@/store'
import moment from 'moment'
const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    VueDatePicker
  },
  data(){
    return {
      showPageTimePicker: true,
      selectedDate: null,
      dataFormat: 'YYYY-MM-DD',
      selectedInterval: 'monthly',
      isLoggedIn: false,
      intervalOptions: [
        { value: 'daily', text: 'Daily' },
        { value: 'weekly', text: 'Weekly' },
        { value: 'monthly', text: 'Monthly' },
      ],
      periodsOptions: [
        { text:'1M',  value: 'month', active: false},
        { text:'1Y',  value: 'year', active: false},
        { text:'YTD', value: 'ytd', active: false},
        { text:'All', value: 'all-time', active: true},
      ],
      originalPeriodsOptions: [
        { text:'1M',  value: 'month', active: false},
        { text:'1Y',  value: 'year', active: false},
        { text:'YTD', value: 'ytd', active: false},
        { text:'All', value: 'all-time', active: true},
      ],
      yearsPeriodsOptions: [
        { text:'1M',  value: 'month', active: false},
        { text:'1Y',  value: 'year', active: false},
        { text:'YTD', value: 'ytd', active: false},
        { text: moment().year(),value: moment().year(),active: false},
        { text: moment().subtract(1, 'year').year(), value: moment().subtract(1, 'year').year(), active: false},
        { text: moment().subtract(2, 'year').year(), value: moment().subtract(2, 'year').year(), active: false },
        { text:'All', value: 'all-time', active: true},
      ],

      hasDate: false,
      selectedDatesRange: null,
      selectedPeriod: 'all-time',
      pageLoader: false,
      showIntervalPicker: true,
    }
  },
  methods: {
    dateChanged(modelData = null){
      this.pageLoader = true
      if (modelData) {
        this.selectedDatesRange = {
          start_date: modelData.start ? `${moment(modelData.start).format(this.dataFormat)}` : '',
          end_date: modelData.end ? `${moment(modelData.end).format(this.dataFormat)}` : ''
        }

        if(this.selectedDatesRange.start_date && this.selectedDatesRange.end_date) {
          this.resetPeriodOptions()
          // 1 - show page loaders default true
          // 2- selected date range for date selector
          // 3- selcted interval
          // 4- selected period required for charts no dates profided
          // 5- apply dates if the dates not provided ?
          this.$store.commit('menuConfig/SET_TIME_PICKER_SELECTOR_LOADER', true)
          this.$store.commit('menuConfig/SET_TIME_PICKER_SELECTOR_DATE', this.selectedDatesRange)
          this.$eventHub.$emit('selectedPageRangeChanged',false, this.selectedDatesRange, this.selectedInterval, this.selectedPeriod, true);
        }
      } else {
        // selectedPeriodDates required for lists has no periods
        // the problem was when we select date we still need to load charts whichs not contain dates just intervals and periods .
        const selectedPeriodDates = this.getDates(this.selectedPeriod)
        this.$store.commit('menuConfig/SET_TIME_PICKER_SELECTOR_LOADER', true)
        this.$store.commit('menuConfig/SET_TIME_PICKER_SELECTOR_DATE', selectedPeriodDates)
        this.$eventHub.$emit('selectedPageRangeChanged',false, selectedPeriodDates , this.selectedInterval, this.selectedPeriod, true);
      }
    },
    getDates(type) {
      let dates = null;
      type = type.toString()
      type = type.indexOf('20')  >= 0 ? 'full-year' : type
      switch(type) {
        case 'all-time':
            dates = null;
        break
        case 'year':
            dates = { start_date: moment().subtract(1,'year').format(this.dataFormat),  end_date: moment().format(this.dataFormat)};
        break
        case 'month':
            dates = { start_date: moment().subtract(1, 'month').format(this.dataFormat),  end_date: moment().format(this.dataFormat)};
        break
        case 'ytd':
            dates = { start_date: moment().startOf('year').format(this.dataFormat),  end_date: moment().format(this.dataFormat)};
        break
        case 'full-year':
          dates = this.selectedPeriod;
        break
        default:
        dates = null;
      }
      return dates;
    },
    clearSelectedDate() {
      this.selectedDate = null;
    },
    changePeriod(index){
      this.selectedDate = null;
      this.resetPeriodOptions()
      this.periodsOptions[index].active = true;
      this.selectedPeriod = this.periodsOptions[index].value
      this.dateChanged()
    },
    changeInterval(val){
      this.selectedInterval = val
      this.dateChanged(this.selectedDate)
    },
    resetPeriodOptions() {
      this.periodsOptions.forEach(element => {
        element.active = false;
      });
    },
    hidePickerLoader(){
      this.pageLoader = false;
    },
    setPeriodsYearOptions(isHome){
      if(isHome){
          this.periodsOptions = this.yearsPeriodsOptions
          this.showIntervalPicker = false;
        } else {
          this.periodsOptions = this.originalPeriodsOptions
          this.showIntervalPicker = true;
        }
    },
  },
  updated() {
    this.isLoggedIn = isUserLoggedIn() ? true : false;
  },
  mounted(){
    if(location.pathname == '/') this.setPeriodsYearOptions(true)

    if(['/','/customers','/rides','/scooters'].indexOf(location.pathname) >= 0) {
        this.showPageTimePicker = true
    } else {
        this.showPageTimePicker = false
    }

    this.$router.beforeEach((to, from, next) => {
    this.resetPeriodOptions();
      let activeIndex = 3
      if(['home','customers','rides','scooters'].indexOf(to.name) >= 0) {
        this.showPageTimePicker = true
       }else {
        this.showPageTimePicker = false
       }

      if(['home'].indexOf(to.name) > -1){
        this.setPeriodsYearOptions(true)
        activeIndex = 6;
      } else {
        this.setPeriodsYearOptions(false)
      }
      this.periodsOptions[activeIndex].active = true;
      this.selectedDate = null;
      this.$store.commit('menuConfig/SET_TIME_PICKER_SELECTOR_LOADER', false)
      this.selectedPeriod = 'all-time';
      return next()
    })

  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    maxDate() {
      const currentDate =  new Date()
      return new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
      );
    }
  },
  beforeCreate() {
    // this.$router.history.listen((newLocation) => {console.log(newLocation);})
    // this.selectedDate = null;
    // this.$router.history.listen((newLocation) => {console.log(newLocation);})
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      store,
    }
  },
}
</script>

<style lang="scss">
.page-date-picker-spinner {
  &.spinner-border-sm {
    border-width: 2px;
    margin: 0;
  }
}
</style>
