import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/api/`,
  timeout: 100000,
})

const resources = {
  adminsLogs: 'activity-logs',
  admins: {
    proTesters: 'admins/pro-testers',
    setNewPassword: 'admins/set-password',
    resetPassword: 'admins/reset-password',
    changePassword: 'admins/change-password',
    home: 'admins/home',
    stats: 'admins/stats',
    sales: 'admins/sales',
    current_year_stats: 'admins/current-year-stats',
    shopifyStats: 'admins/shopify-stats',
    index: 'admins',
    store: 'admins',
    logs: 'admins/:id/logs',
    update: 'admins/:id/update',
    toggle: 'admins/:id/toggle',
    fetch: 'admins/:id',
    destroy: 'admins/:id/destroy',
    target: 'sales-targets',
    fetch_sales: 'sales-targets/fetch',
    yearTarget: 'sales-targets/by-year',
    permissions: 'admins/auth/login/permissions',
  },
  salesTargets: {
    index: 'sales-targets',
    fetch: 'sales-targets/fetch',
    yearly: 'sales-targets/by-year',
  },
  targets: {
    analytics: {
      list: 'targets/analytics',
      update: 'targets/analytics/update',
    },
    stores: {
      list: 'targets/sales',
      update: 'targets/sales/:store/update',
    },
  },
  calendar: {
    files: {
      index: 'admins/calendar/files',
      store: 'admins/calendar/files',
      files: 'admins/calendar/files/list',
      search: 'admins/calendar/files/search',
      destroy: 'admins/calendar/files/:id/destroy',
    },
  },
  roadmap: {
    files: {
      index: 'roadmap/files',
      store: 'roadmap/files',
      reorder: 'roadmap/files/reorder',
      changeStatus: 'roadmap/files/:id/change-status',
      destroy: 'roadmap/files/:id/destroy',
    },
  },
  roles: {
    stats: 'roles/stats',
    index: 'roles',
    store: 'roles',
    update: 'roles/:id/update',
    fetch: 'roles/:id',
  },
  customers: {
    proTesters: 'customers/pro-testers',
    stats: 'customers/stats',
    index: 'customers',
    store: 'customers',
    update: 'customers/:id/update',
    fetch: 'customers/:id',
    scooters: 'customers/:id/scooters',
    tickets: 'customers/:id/tickets',
    orders: 'customers/:id/orders',
    rides: 'customers/:id/rides',
    connectPlus: 'customers/:id/subscriptions',
    connectPlusMovePayment: 'customers/:id/subscriptions/:sid/move-payments',
    setNewPassword: 'customers/set-password',
    resetPassword: 'customers/reset-password',
    unblock: 'customers/unblock',
    charts: 'customers/charts/:type/:interval',
    detailedStats: 'customers/detailed-stats',
    achievements: 'customers/:id/achievements',
    calculations: {
      stats: 'customers/calculations/stats',
      charts: 'customers/calculations/charts',
    },
    points: 'customers/:id/points',
    navigation: 'customers/navigation',
  },
  rides: {
    incidents: 'rides-incidents',
    index: 'rides',
    stats: 'rides/stats',
    scooterStats: 'rides/scooters-stats',
    avgStats: 'rides/avg-stats',
    store: 'rides',
    update: 'rides/:id/update',
    fetch: 'rides/:id',
    destroy: 'rides/:id/destroy',
    cityissues: 'rides/city22-stats',
    charts: 'rides/charts/:type/:interval',
    export: 'rides/:id/export',
    detailedStats: 'rides/detailed-stats',
    requestElevationData: 'rides/:id/elevation',
    calculations: {
      stats: 'rides/calculations/stats',
      charts: 'rides/calculations/charts'
    },
    navigation: 'rides/navigation'
  },
  speedConsents: {
    index: 'speed-consents',
    store: 'speed-consents',
    check: 'speed-consents/check"',
  },
  scooters: {
    index: 'scooters',
    stats: 'scooters/stats',
    sendPin: 'scooters/send-pin',
    resetPin: 'scooters/:id/reset-pin',
    warranty: 'scooters/:id/warranty',
    voidWarranty: 'scooters/warranty/:id/void',
    store: 'scooters',
    update: 'scooters/:id/update',
    fetch: 'scooters/:id',
    destroy: 'scooters/:id/destroy',
    scooterStats: 'scooters/:id/stats',
    update_firmware: 'scooters/:id/update-firmware',
    charts: 'scooters/charts/:type/:interval',
    detailedStats: 'scooters/detailed-stats',
    stolen: 'scooters/:id/stolen-mode',
    calculations: {
      stats: 'scooters/calculations/stats',
      charts: 'scooters/calculations/charts',
    },
    connections: 'scooters/:id/connections',
    changeOwner: 'scooters/:id/change-owner',
    navigation: 'scooters/navigation',
  },
  firmwares: {
    index: 'firmwares',
    store: 'firmwares',
    update: 'firmwares/:id/update',
    fetch: 'firmwares/:id',
    removeFile: 'firmwares/:id/file',
    destroy: 'firmwares/:id/destroy',
    scooterTypes: 'scooter-types',
    changeType: 'firmwares/:id/update',
  },
  emailTemplates: {
    index: 'email-templates',
    update: 'email-templates/:id/update',
  },
  serviceLocators: {
    index: 'service-locators',
    store: 'service-locators',
    update: 'service-locators/:id/update',
    fetch: 'service-locators/:id',
    destroy: 'service-locators/:id/destroy',
  },
  maintenance: {
    backups: {
      list: 'maintenance/list-backups',
      create: 'maintenance/create-backup',
      delete: 'maintenance/delete-backup',
    },
  },
  configurations: {
    countries: '/configurations/settings/countries',
  },
  releaseNotes: {
    index: 'release-notes',
    store: 'release-notes',
    latest: 'release-notes/latest',
    update: 'release-notes/:id',
    delete: 'release-notes/:id/destroy',
    show: 'release-notes/:id',
  },
  iot: {
    disableSimCard: 'iot/v2/sim-cards/suspend',
    enableSimCard: 'iot/v2/sim-cards/resume',
  },
}

Vue.prototype.$http = axiosIns
Vue.prototype.$loading = false
Vue.prototype.$resources = resources
Vue.prototype.$ableTo = perm => (perm === '*') || (JSON.parse(localStorage.getItem('permissions')) || []).includes(perm)

export default axiosIns
