import Vue from 'vue'

const getFilterData = Vue.mixin({
  methods: {
    getFilterData($page, $columns) {
      let $listFilter = localStorage.getItem('list-filter')
      $listFilter = JSON.parse($listFilter)
      const tableFilterData = $listFilter && $listFilter !== undefined && $listFilter[$page] !== undefined ? $listFilter[$page] : {}
      if (tableFilterData.length > 0) {
        tableFilterData.forEach(element => {
          if (element && element.length > 0) {
            $columns.filter(column => column.data === element[0])[0].filterValue = element[1]
          }
        })
        return $columns
      }
      return $columns
    },

    deleteKeyValue($page, $key) {
      const $listFilter = JSON.parse(localStorage.getItem('list-filter'))[$page] ? JSON.parse(localStorage.getItem('list-filter'))[$page] : {}
      $listFilter[$key] = {}
      localStorage.setItem('list-filter', JSON.stringify($listFilter))
    },
  },
})

const setFilterData = Vue.mixin({
  methods: {
    setFilterData($page, loadOptions) {
      let $filterArray = []
      const $listFilter = localStorage.getItem('list-filter') ? JSON.parse(localStorage.getItem('list-filter')) : { }
      if (loadOptions && loadOptions.filter) {
        const { filter } = loadOptions
        const isStringsArray = filter.every(i => !i || !i.columnIndex)
        if (isStringsArray) {
          $filterArray.push([filter[0], filter[2]])
        } else {
          filter.forEach(element => {
            if (element && typeof element !== 'string') {
              $filterArray.push([element[0], element[2]])
            }
          })
        }
        $filterArray = $filterArray.filter(element => {
          if ($listFilter[$page] !== undefined && $listFilter[$page].length > 0 && element) {
            $listFilter[$page] = $listFilter[$page].filter(item => item && item[0] !== element[0])
          }
          return element[1] ?? element
        })
        $listFilter[$page] = $filterArray.concat($listFilter[$page])
        localStorage.setItem('list-filter', JSON.stringify($listFilter))
      } else {
        $listFilter[$page] = {}
        localStorage.setItem('list-filter', JSON.stringify($listFilter))
      }
    },
  },
})




const deleteKeyValue = Vue.mixin({
  methods: {
    deleteKeyValue($page, $key) {
      const $listFilter = JSON.parse(localStorage.getItem('list-filter'))[$page] ? JSON.parse(localStorage.getItem('list-filter'))[$page] : {}
      $listFilter[$key] = {}
      localStorage.setItem('list-filter', JSON.stringify($listFilter))
    },
  },
})

const getSearchData = Vue.mixin({
  methods: {
    async getSearchData($page) {
        const  $filterData = localStorage.getItem('list-filter') ? JSON.parse(localStorage.getItem('list-filter'))[$page] : null;
        if(!isObjEmpty($filterData)) {
          var filtered = $filterData.filter(function (el) {
            return el.length > 0;
          });
          const  data  =  filtered.map(item => ({ 'key': item[0],  'value': item[1] }))
          return data;
        }
        return null
        
    },
  },
})


function isObjEmpty (obj) {
  return !obj || Object.keys(obj).length === 0;
}



const setSortData = Vue.mixin({
  methods: {
    setSortData($page, sortArray) {
      const $sortArray = [];
      let $listSort = {};
      $listSort[$page] = {}




      if (sortArray && sortArray.length > 0) {
        sortArray.forEach(element => {
              $sortArray.push({
                selector: element.selector,
                direction: element.desc ? 'desc' : 'asc'
            })
        })
        $listSort[$page] = $sortArray
        localStorage.setItem('list-sort', JSON.stringify($listSort))
      } else {
        $listSort[$page] = {}
        localStorage.setItem('list-sort', JSON.stringify($listSort))
      }
    },
  },
})


const getSortData = Vue.mixin({
  methods: {
    async getSortData($page) {
      let $listSort = {};
      const $sort = JSON.parse(localStorage.getItem('list-sort'))[$page]
      $listSort =  $sort ?  $sort : null;
      return $listSort;
    },
  },
})



export {
  setFilterData,
  getFilterData,
  deleteKeyValue,
  getSearchData,
  setSortData,
  getSortData
}
