import Vue from 'vue'

const containsAny = Vue.mixin({
  methods: {
    containsAny(str, substrings) {
      for (let i = 0; i !== substrings.length; i++) {
        const substring = substrings[i]
        if (str.indexOf(substring) !== -1) {
          return true
        }
      }
      return false
    },
  },
})

export {
  containsAny
}
